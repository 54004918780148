import { useCallback, useState } from 'react';
import type { Order } from '@wilm/shared-types/cart/Order';
import { useCart } from 'frontastic';

const OFFSET_DEFAULT_VALUE = 0;
const LIMIT_DEFAULT_VALUE = 15;

const useOrderHistory = () => {
    const { getFullOrderHistory } = useCart();
    const [loading, setLoading] = useState(true);
    const [fullOrderHistory, setFullOrderHistory] = useState<{ limit: number; offset: number; total: number; orders: Order[] }>();

    const fetchOrderHistory = useCallback(
        (offset?: number, limit?: number) => {
            setLoading(true);

            const offsetWithDefaultValue = offset ?? OFFSET_DEFAULT_VALUE;
            const limitWithDefaultValue = limit ?? LIMIT_DEFAULT_VALUE;

            getFullOrderHistory(offsetWithDefaultValue, limitWithDefaultValue)
                .then(response => {
                    setFullOrderHistory(response);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        },
        [getFullOrderHistory]
    );

    return {
        fetchOrderHistory,
        loading,
        fullOrderHistory
    };
};

export default useOrderHistory;
