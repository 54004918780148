import React, { useCallback, useEffect, useState } from 'react';
import type { Order } from '@wilm/shared-types/cart/Order';
import { v4 as uuid } from 'uuid';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import useOrderHistory from './helper-hooks/useOrderHistory';
import OrderItem from './OrderItem';

const Orders = () => {
    const { fullOrderHistory, fetchOrderHistory, loading } = useOrderHistory();
    const [pagination, setPagination] = useState<{ currentPage: number; totalPages: number; offset: number; limit: number }>({
        currentPage: 1,
        totalPages: 1,
        offset: 0,
        limit: 15
    });

    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

    useEffect(() => {
        fetchOrderHistory(0, 15);
    }, [fetchOrderHistory]);

    const moveToPageHandler = useCallback(
        (page: number) => {
            if (!loading) {
                const nextOffset = (page - 1) * pagination.limit;
                fetchOrderHistory(nextOffset, pagination.limit);
            }
        },
        [pagination, fetchOrderHistory, loading]
    );

    useEffect(() => {
        if (fullOrderHistory) {
            const totalPages = Math.ceil(fullOrderHistory.total / fullOrderHistory.limit) ?? 1;
            const currentPage = Math.ceil(fullOrderHistory.offset / fullOrderHistory.limit) + 1;

            setPagination({
                currentPage: currentPage,
                totalPages: totalPages,
                limit: fullOrderHistory.limit,
                offset: fullOrderHistory.offset
            });
        }
    }, [fullOrderHistory]);

    return (
        <>
            <Typography as="h2" className="ml-15 mt-22 text-18 text-primary-black md:ml-24 md:text-22 lg:ml-44 lg:mt-42">
                {formatOrdersMessage({
                    id: 'orders',
                    defaultMessage: 'Orders'
                })}
            </Typography>

            <div className="mb-5 mt-15 px-16 md:mt-20 md:px-24 lg:mb-18 lg:px-44">
                <Typography as="h3" className="text-md text-secondary-black md:text-16">
                    {formatOrdersMessage({
                        id: 'help.question',
                        defaultMessage: 'Check status of recent orders, manage your returns and download invoices.'
                    })}
                </Typography>
            </div>
            <div className="overflow-auto px-15 pt-15 md:px-24 lg:px-44">
                {loading ? (
                    <OrderItem loading={loading} />
                ) : (
                    fullOrderHistory?.orders?.map((order?: Order) => <OrderItem key={order?.orderId} order={order} />)
                )}
            </div>

            {!loading && (
                <div className="overflow-auto px-15 pt-15 md:px-24 lg:px-44">
                    {[...Array(pagination.totalPages)].map((_p, i) => (
                        <Button
                            key={uuid()}
                            variant={pagination.currentPage === i + 1 ? 'secondary' : 'primary'}
                            size="xs"
                            className="ml-8 mt-20 rounded-md px-16 py-8 xl:mt-0"
                            onClick={() => moveToPageHandler(i + 1)}
                        >
                            <Typography>{(i + 1).toString()}</Typography>
                        </Button>
                    ))}
                </div>
            )}
        </>
    );
};

export default Orders;
